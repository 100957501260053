<script>
import axios from 'axios'
import Layout from "../layouts/main";
import PageHeader from "@/components/page-header";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

/**
 * recipients Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Multiselect
  },
  data() {
    return {
      title: "Recipients",
      recipients: {
            f_name : '',
            l_name : '',
            company_name : '',
            user_id : '',
            des_country : '',
            bank_name : '',
            usd_bank_name : '',
            acc_number : '',
            phone_n : '',
            iban : '',
            swift : '',
            bsb_code : '',
            ifsc : '',
            bik_code : '',
            transit_code : '',
            sort_code : '',
            branch_name : '',
            branch_code : '',
            address : '',
            bank_add : '',
            city : '',
            province_r : '',
            postalcode : '',
            region : '',
            email : '',
            account_type : '',
            id_type : '',
            id_number : '',
            birth_date : '',
            nationality : '',
            occupation: '',
            birth_place: '',
      },
      submitted: false,
      showmodal: false,
      showmodal1: false,
      recipient_type: 2,
      forms:[],
      destination_country:[],
      bank_list:[],
      users:[],
      recs:[],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      isDisable : false,
      list_country: [],
      data:[],
      fields: [
        { key: "rec_types", label:'Type' },
        { key: "sender_name", label:"Sender Name" },
        { key: "recipient_name", label:"Recipient Name" },
        { key: "des_country", label:"Destination Country" },
        { key: "nama_bank", label : "Bank Name" },
        { key: "primary", label:"Account Number/Iban/Alipay Number" },
        { key: "action" },
        // { key: "detail" },
        // { key: "send" },
      ],
      isBusy: false,
    };
  },
  validations: {
    recipients: {
      user_id : {
        required,
      },
      des_country : {
        required
      },
      bank_name : {
        required,
      },
      province_r : {
        required,
      }
    },
  },
  computed: {
    rows() {
      return this.recs.length;
    }
  },
  mounted() {
    this.totalRows = this.recs.length;
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    handleSubmit() {
      this.submitted = true;
      this.isDisable = true;
      let admin = JSON.parse(localStorage.getItem("user")).id;
      this.$v.recipients.user_id.$touch();
      this.$v.recipients.des_country.$touch();
      this.$v.recipients.bank_name.$touch();

      if(!this.$v.recipients.user_id.$invalid && !this.$v.recipients.des_country.$invalid && !this.$v.recipients.bank_name.$invalid ) {
          let data ={
            f_name: this.recipients.f_name,
            l_name: this.recipients.l_name,
            company_name: this.recipients.company_name,
            des_country: this.recipients.des_country.country,
            acc_number: this.recipients.acc_number,
            phone_n: this.recipients.phone_n,
            bank_name: this.recipients.bank_name.id_bank ? this.recipients.bank_name.id_bank : null,
            address: this.recipients.address,
            create_by: this.recipients.user_id.user_id,
            admin: admin,
            bsb_num: this.recipients.bsb_code,
            bank_branch: this.recipients.branch_name,
            branch_code: this.recipients.branch_code,
            ifsc_code: this.recipients.ifsc,
            swift_code: this.recipients.swift,
            iban_code: this.recipients.iban,
            bank_address: this.recipients.bank_add,
            address_city: this.recipients.city,
            address_prov: this.recipients.province_r ? this.recipients.province_r : null,
            address_post: this.recipients.postalcode,
            acc_type_r: this.recipients.account_type,
            rec_type : this.recipient_type,
            transit_code: this.recipients.transit_code,   
            email: this.recipients.email,
            birth_date : this.recipients.birth_date,
            id_type : this.recipients.id_type,
            id_number : this.recipients.id_number,
            bik_code : this.recipients.bik_code,
            sort_code : this.recipients.sort_code,
            nationality : this.recipients.nationality,
            usd_bank_name: this.recipients.usd_bank_name,
            birth_place: this.recipients.birth_place,
            occupation: this.recipients.occupation,
        };
        axios.post(`v1/add-rec-aff`, data)
        .then((res) => {
            if(res.data.success){
                Swal.fire("Success", "Recipient was created", "success");
                this.getRecipient();
                this.showmodal = false;
                this.submitted = false;
                this.onChange2();
                this.isDisable = false;
            } else {
                Swal.fire("Error", "Something Wrong!", "error");
                this.submitted = false;
                this.isDisable = false;
            }
        })
      } else {
        Swal.fire("Warning", "Select Required", "error");
        this.isDisable = false;
        this.submitted = false;
      }
    },
    onChange(){
        this.recipients.user_id = [],
        this.recipients.f_name = null,
        this.recipients.l_name = null,
        this.recipients.company_name = null,
        this.recipients.des_country = [],
        this.recipients.bank_name = [],
        this.recipients.usd_bank_name = null,
        this.recipients.acc_number = null,
        this.recipients.phone_n = null,
        this.recipients.iban = null,
        this.recipients.swift = null,
        this.recipients.bsb_code = null,
        this.recipients.ifsc = null,
        this.recipients.bik_code = null,
        this.recipients.transit_code = null,
        this.recipients.sort_code = null,
        this.recipients.branch_name = null,
        this.recipients.branch_code = null,
        this.recipients.address = null,
        this.recipients.bank_add = null,
        this.recipients.city = null,
        this.recipients.province_r = '',
        this.recipients.postalcode = null,
        this.recipients.region = null,
        this.recipients.email = null,
        this.recipients.account_type = null,
        this.recipients.id_type = null,
        this.recipients.id_number = null
        this.recipient_type = 1
        axios.get(`v1/get-field/1/myr`)
        .then((res) => {
            this.forms = res.data.remitter_parameters.recipient_information;
        })
    },
    onChange2(){
        this.recipients.user_id = [ ],
        this.recipients.f_name = null,
        this.recipients.l_name = null,
        this.recipients.company_name = null,
        this.recipients.des_country = [ ],
        this.recipients.bank_name = [ ],
        this.recipients.usd_bank_name = null,
        this.recipients.acc_number = null,
        this.recipients.phone_n = null,
        this.recipients.iban = null,
        this.recipients.swift = null,
        this.recipients.bsb_code = null,
        this.recipients.ifsc = null,
        this.recipients.bik_code = null,
        this.recipients.transit_code = null,
        this.recipients.sort_code = null,
        this.recipients.branch_name = null,
        this.recipients.branch_code = null,
        this.recipients.address = null,
        this.recipients.bank_add = null,
        this.recipients.city = null,
        this.recipients.province_r = '',
        this.recipients.postalcode = null,
        this.recipients.region = null,
        this.recipients.email = null,
        this.recipients.account_type = null,
        this.recipients.id_type = null,
        this.recipients.id_number = null
        this.recipient_type = 2
         axios.get(`v1/get-field/2/myr`)
        .then((res) => {
            this.forms = res.data.remitter_parameters.recipient_information;
        })
    },
    hideModal() {
        this.submitted = false;
        this.showmodal = false;
    },
    getSender(){
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get-sender/${id}`)
        .then((res) => {
            this.users = res.data.user
            this.users.forEach(function (element) {
              element.item = `${element.sender_name} - ${element.idcard_num}`;
            })
        })
    },
    getCountry(){
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
        axios.get(`v1/get-rate/${id}`)
        .then((res) => {
            this.destination_country = res.data.data
            this.list_country = this.destination_country.map(function(x) {
              return x.country
            })
          //  console.log( this.inArray('China',this.list_country))
          //  console.log( this.inArray('China', this.list_country))
        })
    },
    inArray(a, b) {
        var length = b.length;
        for(var i = 0; i < length; i++) {
            if(b[i] == a) return true;
        }
        return false;
    },
    getBanklist(){
      this.recipients.f_name = null;
      this.recipients.l_name = null;
      this.recipients.company_name = null;
      this.recipients.bank_name = '';
      this.recipients.usd_bank_name = null;
      this.recipients.acc_number = null;
      this.recipients.phone_n = null;
      this.recipients.iban = null;
      this.recipients.swift = null;
      this.recipients.bsb_code = null;
      this.recipients.ifsc = null;
      this.recipients.bik_code = null;
      this.recipients.transit_code = null;
      this.recipients.sort_code = null;
      this.recipients.branch_name = null;
      this.recipients.branch_code = null;
      this.recipients.address = null;
      this.recipients.bank_add = null;
      this.recipients.city = null;
      this.recipients.province_r = '';
      this.recipients.postalcode = null;
      this.recipients.region = null;
      this.recipients.email = null;
      this.recipients.account_type = null;
      this.recipients.id_type = null;
      this.recipients.id_number = null;
      axios.get(`v1/get-field/1/${this.recipients.des_country.currency_code}`)
        .then((res) => {
          this.forms = res.data.remitter_parameters.recipient_information;
        })
      axios.get(`v1/banklist-vendor/${this.recipients.des_country.currency_code}`)
      .then((res) => {
          this.bank_list = res.data.data;
      })
    },
    getBanklist2(){
      this.recipients.f_name = null;
      this.recipients.l_name = null;
      this.recipients.company_name = null;
      // this.recipients.des_country = null;
      this.recipients.bank_name = '';
      this.recipients.usd_bank_name = null;
      this.recipients.acc_number = null;
      this.recipients.phone_n = null;
      this.recipients.iban = null;
      this.recipients.swift = null;
      this.recipients.bsb_code = null;
      this.recipients.ifsc = null;
      this.recipients.bik_code = null;
      this.recipients.transit_code = null;
      this.recipients.sort_code = null;
      this.recipients.branch_name = null;
      this.recipients.branch_code = null;
      this.recipients.address = null;
      this.recipients.bank_add = null;
      this.recipients.city = null;
      this.recipients.province_r = '';
      this.recipients.postalcode = null;
      this.recipients.region = null;
      this.recipients.email = null;
      this.recipients.account_type = null;
      this.recipients.id_type = null;
      this.recipients.id_number = null;
      axios.get(`v1/get-field/2/${this.recipients.des_country.currency_code}`)
        .then((res) => {
          this.forms = res.data.remitter_parameters.recipient_information;
        })
      axios.get(`v1/banklist-vendor/${this.recipients.des_country.currency_code}`)
      .then((res) => {
          this.bank_list = res.data.data;
      })
    },
    getRecipient(){
      this.isBusy = true;
      let id  = JSON.parse(localStorage.getItem("user")).user_id;
      axios.get(`v1/get-recipient/${id}`)
        .then((res) => {
          this.isBusy = false;
          this.recs = res.data.success
          this.recs.forEach(function (element) {
            element.primary = `${element.acc_number ? element.acc_number : element.iban ? element.iban : element.phone_n}`
          })
          if(!JSON.parse(localStorage.getItem("user")).id){
            let Item = { key : 'admin_name'}
            this.fields.splice(6,0,Item)
          }
        })
    },
    send(e){
      let targetId = e.item.des_country
      let sender = e.item.user_id
      let rec = e.item.id_rec
      this.$router.push({ name: "send" , params:{country:targetId,ben:rec,sender:sender}})
    },
    detail(e){
      this.showmodal1 = true;
      let id = e.item.user_id
      axios.get(`v1/get-recs/${id}`)
      .then((res) => {
        this.data = res.data.success.find((
          (x) => x.id_rec == e.item.id_rec
        ))
      })
    },
    deletes(e){
      let id = e.item.id_rec
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-danger ml-2",
          cancelButton: "btn btn-secondary"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          showCancelButton: true,
          reverseButtons: true,
        }) 
        .then(result => {
          if (result.value) {
            axios.post(`/v1/del_recs/${id}`)
            .then((res) => {
              if(res.data.success){
                this.getRecipient();
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Recipient has been deleted.",
                  "success"
                );
              }
            })
          }
        })
    },
  },
  created () {
      this.getCountry();
      this.getSender();
      this.getRecipient();
      axios.get(`v1/get-field/2/idr`)
      .then((res) => {
          this.forms = res.data.remitter_parameters.recipient_information;
      })
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <a href="javascript:void(0);" class="btn btn-primary mb-2" @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Add Recipient
              </a>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="">
                  <div class="card-body">
                    <div class="row mt-4">
                      <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                          <label class="d-inline-flex align-items-center">
                            Show&nbsp;
                            <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                          </label>
                        </div>
                      </div>
                      <!-- Search -->
                      <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                          <label class="d-inline-flex align-items-center">
                            Search:
                            <b-form-input
                              v-model="filter"
                              type="search"
                              placeholder="Search..."
                              class="form-control form-control-sm ml-2"
                            ></b-form-input>
                          </label>
                        </div>
                      </div>
                      <!-- End search -->
                    </div>
                    <!-- Table -->
                    <div class="table-responsive mb-0">
                      <b-table
                        :items="recs"
                        :fields="fields"
                        striped
                        responsive="sm"
                        :per-page="perPage"
                        :current-page="currentPage"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                        @filtered="onFiltered"
                        :busy="isBusy"
                        show-empty
                        fixed
                      >
                      <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{ width: field.key === 'nama_bank' || field.key === 'recipient_name' || field.key === 'sender_name' ? '190px' : '125px' }"
                      >
                      </template>
                      <template v-slot:cell(nama_bank)="row">
                        <div
                        >{{row.item.usd_bank_name ? row.item.usd_bank_name + ' (' + row.item.nama_bank + ')' : row.item.nama_bank}}</div>
                      </template>
                      <template v-slot:cell(admin)="row">
                        <div
                        >{{row.item.admin_name}}</div>
                      </template>
                      <template v-slot:cell(action)="row">
                        <a
                        @click="detail(row)"
                        href="javascript:void(0);"
                        class="mr-3 text-info"
                        v-b-tooltip.hover
                        title="Detail"
                      >
                        <i class="mdi mdi-file-eye" style="font-size:1.3rem"></i>
                      </a>
                      <a
                        @click="deletes(row)"
                        href="javascript:void(0);"
                        class="mr-3 text-danger"
                        v-b-tooltip.hover
                        title="Delete"
                      >
                        <i class="mdi mdi-trash-can" style="font-size:1.3rem"></i>
                      </a>
                      <a
                        @click="send(row)"
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        title="Send"
                        :hidden="!inArray(row.item.des_country, list_country)"
                      >
                        <i class="mdi mdi-telegram" style="font-size:1.3rem"></i>
                      </a>
                      
                      </template>
                      <template #table-busy>
                        <div class="text-center text-danger my-2">
                          <b-spinner class="align-middle mr-2"></b-spinner>
                          <strong>Loading...</strong>
                        </div>
                      </template>
                      <template #empty>
                        <h5 class='empty'>No Recipients Data ...</h5>
                      </template>
                      <!-- <template v-slot:cell(detail)="row">
                        <button @click="detail(row)" class="btn btn-primary">
                          <i class="mdi mdi-file-eye"></i>
                        </button>
                      </template>
                      <template v-slot:cell(send)="row">
                        <button @click="send(row)" class="btn btn-primary">
                          <i class="mdi mdi-telegram"></i>
                        </button>
                      </template> -->
                      </b-table>
                    </div>
                    <!-- <template v-else>
                      <h5 class='empty'>No Recipient Data ...</h5>
                    </template> -->
                    <div class="row">
                      <div class="col">
                        <div class="dataTables_paginate paging_simple_numbers float-right">
                          <ul class="pagination pagination-rounded mb-0">
                            <!-- pagination -->
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodal"
      title="Add Recipient"
      title-class="text-dark font-18"
      hide-footer
      scrollable
      @show="onChange2"
      @hidden="onChange2"
    >
      <form @submit.prevent="handleSubmit">
        <b-tabs content-class="p-3 text-muted">
          <b-tab active class="border-0"  @click="onChange2">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Business</span>
                </template>
                <template v-if="forms.f_name">
                  <div class="form-group row">
                    <label for="id_card_type">Select Sender</label>
                     <multiselect 
                        v-model="recipients.user_id" 
                        :options="users"
                        label="item"
                        :selectLabel="null"
                        :deselectLabel="null"
                        :allow-empty="false"
                        placeholder="Select Sender"
                        required
                    ></multiselect>
                    <small
                      class="mb-0 text-danger"
                      v-if="
                        !$v.recipients.bank_name.required &&
                        $v.recipients.bank_name.$error
                      "
                    >
                      Select Sender is required
                    </small>
                </div>
                <div class="form-group row">
                    <label for="des_country">Destination Country</label>
                    <multiselect 
                        v-model="recipients.des_country" 
                        :options="destination_country"
                        label="country"
                        :selectLabel="null"
                        :deselectLabel="null"
                        :allow-empty="false"
                        placeholder="Select Destination Country"
                        @input="getBanklist2"
                        required
                    ></multiselect>
                    <small
                      class="mb-0 text-danger"
                      v-if="
                        !$v.recipients.bank_name.required &&
                        $v.recipients.bank_name.$error
                      "
                    >
                      Select Destination Country is required
                    </small>
                </div>
                <div class="form-group row" v-if="forms.company_name.mandatory">
                    <label for="com_name">Company Name</label>
                    <input
                        id="com_name"
                        v-model="recipients.company_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter Company Name"
                        required
                    />
                </div>
                <div class="form-group row">
                    <template v-if="this.recipients.des_country.country == 'Alibaba USD' || 
                    this.recipients.des_country.country == 'USD Worldwide' || 
                    this.recipients.des_country.country == 'Euro Worldwide'">
                      <label for="bank_name">Country</label>
                      <multiselect 
                          v-model="recipients.bank_name" 
                          :options="bank_list"
                          label="nama_bank"
                          :selectLabel="null"
                          :deselectLabel="null"
                          :allow-empty="false"
                          placeholder="Select Country"
                          required
                      ></multiselect>
                    </template>
                    <template v-else>
                      <label for="bank_name">Bank Name</label>
                      <multiselect 
                          v-model="recipients.bank_name" 
                          :options="bank_list"
                          label="nama_bank"
                          :selectLabel="null"
                          :deselectLabel="null"
                          :allow-empty="false"
                          placeholder="Select Bank Name"
                        required
                      ></multiselect>
                    </template>
                    <small
                      class="mb-0 text-danger"
                      v-if="
                        !$v.recipients.bank_name.required &&
                        $v.recipients.bank_name.$error
                      "
                    >
                      Select is required
                    </small>
                </div>
                <div class="form-group row" v-if="forms.usd_bank_name.mandatory">
                    <label for="usd_bank_name">Bank Name</label>
                    <input
                        id="usd_bank_name"
                        v-model="recipients.usd_bank_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter USD Bank Name"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.acc_number.mandatory">
                    <label for="acc_number" v-if="this.recipients.des_country.country ==  'Argentina'">CBU</label>
                    <label for="acc_number" v-else>Account Number</label>
                    <input
                        id="acc_number"
                        v-model="recipients.acc_number"
                        type="text"
                        class="form-control"
                        :placeholder="this.recipients.des_country.country ==  'Argentina' ? 'Enter CBU' :'Enter Account Number'"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.phone_n.mandatory">
                    <label for="phone_n">Phone Number</label>
                    <input
                        id="phone_n"
                        v-model="recipients.phone_n"
                        type="number"
                        class="form-control"
                        placeholder="Enter Phone Number"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.iban.mandatory">
                    <label for="iban">IBAN</label>
                    <input
                        id="iban"
                        v-model="recipients.iban"
                        type="text"
                        class="form-control"
                        placeholder="Enter IBAN"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.swift.mandatory">
                    <label for="swift">Swift Code</label>
                    <input
                        id="swift"
                        v-model="recipients.swift"
                        type="text"
                        class="form-control"
                        placeholder="Enter Swift Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.bsb_code.mandatory">
                    <label for="bsb_code">BSB Number</label>
                    <input
                        id="bsb_code"
                        v-model="recipients.bsb_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter BSB Number"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.ifsc.mandatory">
                    <label for="ifsc">IFSC</label>
                    <input
                        id="ifsc"
                        v-model="recipients.ifsc"
                        type="text"
                        class="form-control"
                        placeholder="Enter IFSC"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.bik_code.mandatory">
                    <label for="bik_code">BIK Code</label>
                    <input
                        id="bik_code"
                        v-model="recipients.bik_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter BIK Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.transit_code.mandatory">
                    <label for="transit_code">Transit Code</label>
                    <input
                        id="transit_code"
                        v-model="recipients.transit_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter Transit Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.sort_code.mandatory">
                    <label for="sort_code">Sort Code</label>
                    <input
                        id="sort_code"
                        v-model="recipients.sort_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter Sort Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.branch_name.mandatory">
                    <label for="branch_name">Branch Name</label>
                    <input
                        id="branch_name"
                        v-model="recipients.branch_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter Branch Name"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.branch_code.mandatory">
                    <label for="branch_code">Branch Code</label>
                    <input
                        id="branch_code"
                        v-model="recipients.branch_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter Branch Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.bank_add.mandatory">
                    <label for="bank_add">Bank Address</label>
                    <input
                        id="bank_add"
                        v-model="recipients.bank_add"
                        type="text"
                        class="form-control"
                        placeholder="Enter Bank Address"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.address.mandatory">
                    <label for="address">Address</label>
                    <input
                        id="address"
                        v-model="recipients.address"
                        type="text"
                        class="form-control"
                        placeholder="Enter Address"
                        required
                    />
                </div>
                
                <div class="form-group row" v-if="forms.city.mandatory">
                    <label for="city">City</label>
                    <input
                        id="city"
                        v-model="recipients.city"
                        type="text"
                        class="form-control"
                        placeholder="Enter City"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.province_r.mandatory">
                    <label for="province_r">Province</label>
                    <select class="form-control" v-model="recipients.province_r" required>
                      <option value='' hidden>Select Province</option>
                        <option value="7">Australia Capital</option>
                        <option value="1">New South Wales</option>
                        <option value="8">Northern Territory</option>
                        <option value="2">Queensland</option>
                        <option value="3">South Australia</option>
                        <option value="4">Tasmania</option>
                        <option value="5">Victoria</option>
                        <option value="6">Western Australia</option>
                    </select>
                </div>
                <div class="form-group row" v-if="forms.postalcode.mandatory">
                    <label for="postalcode">Postal Code</label>
                    <input
                        id="postalcode"
                        v-model="recipients.postalcode"
                        type="text"
                        class="form-control"
                        placeholder="Enter Postal Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.region.mandatory">
                    <label for="region">Region</label>
                    <input
                        id="region"
                        v-model="recipients.region"
                        type="text"
                        class="form-control"
                        placeholder="Enter Region"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.email.mandatory">
                    <label for="email">Email</label>
                    <input
                        id="email"
                        v-model="recipients.email"
                        type="text"
                        class="form-control"
                        placeholder="Enter Email"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.account_type.mandatory">
                    <label for="account_type">Account Type</label>
                    <input
                        id="account_type"
                        v-model="recipients.account_type"
                        type="text"
                        class="form-control"
                        placeholder="Enter Account Type"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.id_type.mandatory">
                    <label for="id_type">ID Type</label>
                    <input
                        id="id_type"
                        v-model="recipients.id_type"
                        type="text"
                        class="form-control"
                        placeholder="Enter ID Type"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.id_number.mandatory">
                    <label for="id_number">ID Number</label>
                    <input
                        id="id_number"
                        v-model="recipients.id_number"
                        type="text"
                        class="form-control"
                        placeholder="Enter ID Number"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.birth_date.mandatory">
                    <label for="birth_date">Birth Date</label>
                    <input
                        id="birth_date"
                        v-model="recipients.birth_date"
                        type="date"
                        class="form-control"
                        placeholder="Enter Birth Date"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.nationality.mandatory">
                    <label for="nationality">Nationality</label>
                    <input
                        id="nationality"
                        v-model="recipients.nationality"
                        type="text"
                        class="form-control"
                        placeholder="Enter Nationality"
                        required
                    />
                </div>
                <div class="text-right">
                    <b-button variant="secondary" @click="hideModal">Cancel</b-button>
                    <button type="submit" class="btn btn-success ml-1" style="width:5rem" :disabled="isDisable">
                      <span v-if="submitted"><b-spinner class="p-0" style="width:20px;height:20px" variant="black" role="status"></b-spinner></span>
                      <span v-else>Submit</span>
                    </button>
                </div>
                </template>
            </b-tab>
            <b-tab class="border-0" @click="onChange">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Personal</span>
                </template>
                <template v-if="forms.f_name">
                  <div class="form-group row">
                    <label for="id_card_type">Select Sender</label>
                     <multiselect 
                        v-model="recipients.user_id" 
                        :options="users"
                        label="item"
                        :selectLabel="null"
                        :deselectLabel="null"
                        :allow-empty="false"
                        placeholder="Select Sender"
                        required
                    ></multiselect>
                    <small
                    class="mb-0 text-danger"
                    v-if="
                      !$v.recipients.user_id.required &&
                      $v.recipients.user_id.$error
                    "
                  >
                    Select Sender is required
                  </small>
                </div>
                <div class="form-group row">
                    <label for="des_country">Destination Country</label>
                    <multiselect 
                        v-model="recipients.des_country" 
                        :options="destination_country"
                        label="country"
                        :selectLabel="null"
                        :deselectLabel="null"
                        :allow-empty="false"
                        placeholder="Select Destination Country"
                        @input="getBanklist"
                        required
                    ></multiselect>
                    <small
                    class="mb-0 text-danger"
                    v-if="
                      !$v.recipients.des_country.required &&
                      $v.recipients.des_country.$error
                    "
                  >
                    Select Destination Country is required
                  </small>
                </div>
                <div class="form-group row" v-if="forms.f_name.mandatory">
                    <label for="fname">First Name</label>
                    <input
                        id="fname"
                        v-model="recipients.f_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter First Name"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.l_name.mandatory">
                    <label for="lname">Last Name</label>
                    <input
                        id="lname"
                        v-model="recipients.l_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter Last Name"
                        required
                    />
                </div>
                <div class="form-group row">
                    <template v-if="this.recipients.des_country.country == 'Alibaba USD' || 
                    this.recipients.des_country.country == 'USD Worldwide'">
                      <label for="bank_name">Country</label>
                      <multiselect 
                          v-model="recipients.bank_name" 
                          :options="bank_list"
                          label="nama_bank"
                          :selectLabel="null"
                          :deselectLabel="null"
                          :allow-empty="false"
                          placeholder="Select Country"
                        required
                      ></multiselect>
                    </template>
                    <template v-else>
                      <label for="bank_name">Bank Name</label>
                      <multiselect 
                          v-model="recipients.bank_name" 
                          :options="bank_list"
                          label="nama_bank"
                          :selectLabel="null"
                          :deselectLabel="null"
                          :allow-empty="false"
                          placeholder="Select Bank Name"
                        required
                      ></multiselect>
                    </template>
                      <small
                      class="mb-0 text-danger"
                      v-if="
                        !$v.recipients.bank_name.required &&
                        $v.recipients.bank_name.$error
                      "
                    >
                      Select is required
                    </small>
                </div>
                <div class="form-group row" v-if="forms.usd_bank_name.mandatory">
                    <label for="usd_bank_name">USD Bank Name</label>
                    <input
                        id="usd_bank_name"
                        v-model="recipients.usd_bank_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter USD Bank Name"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.acc_number.mandatory">
                    <label for="acc_number">Account Number</label>
                    <input
                        id="acc_number"
                        v-model="recipients.acc_number"
                        type="text"
                        class="form-control"
                        placeholder="Enter Account Number"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.phone_n.mandatory">
                    <label for="phone_n">Phone Number</label>
                    <input
                        id="phone_n"
                        v-model="recipients.phone_n"
                        type="number"
                        class="form-control"
                        placeholder="Enter Phone Number"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.iban.mandatory">
                    <label for="iban">IBAN</label>
                    <input
                        id="iban"
                        v-model="recipients.iban"
                        type="text"
                        class="form-control"
                        placeholder="Enter IBAN"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.swift.mandatory">
                    <label for="swift">Swift Code</label>
                    <input
                        id="swift"
                        v-model="recipients.swift"
                        type="text"
                        class="form-control"
                        placeholder="Enter Swift Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.bsb_code.mandatory">
                    <label for="bsb_code">BSB Number</label>
                    <input
                        id="bsb_code"
                        v-model="recipients.bsb_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter BSB Number"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.ifsc.mandatory">
                    <label for="ifsc">IFSC</label>
                    <input
                        id="ifsc"
                        v-model="recipients.ifsc"
                        type="text"
                        class="form-control"
                        placeholder="Enter IFSC"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.bik_code.mandatory">
                    <label for="bik_code">BIK Code</label>
                    <input
                        id="bik_code"
                        v-model="recipients.bik_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter BIK Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.transit_code.mandatory">
                    <label for="transit_code">Transit Code</label>
                    <input
                        id="transit_code"
                        v-model="recipients.transit_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter Transit Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.sort_code.mandatory">
                    <label for="sort_code">Sort Code</label>
                    <input
                        id="sort_code"
                        v-model="recipients.sort_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter Sort Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.branch_name.mandatory">
                    <label for="branch_name">Branch Name</label>
                    <input
                        id="branch_name"
                        v-model="recipients.branch_name"
                        type="text"
                        class="form-control"
                        placeholder="Enter Branch Name"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.branch_code.mandatory">
                    <label for="branch_code">Branch Code</label>
                    <input
                        id="branch_code"
                        v-model="recipients.branch_code"
                        type="text"
                        class="form-control"
                        placeholder="Enter Branch Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.bank_add.mandatory">
                    <label for="bank_add">Bank Address</label>
                    <input
                        id="bank_add"
                        v-model="recipients.bank_add"
                        type="text"
                        class="form-control"
                        placeholder="Enter Bank Address"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.city.mandatory">
                    <label for="city">City</label>
                    <input
                        id="city"
                        v-model="recipients.city"
                        type="text"
                        class="form-control"
                        placeholder="Enter City"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.province_r.mandatory">
                    <label for="province_r">Province</label>
                    <select class="form-control" v-model="recipients.province_r" required>
                      <option value='' hidden>Select Province</option>
                        <option value="7">Australia Capital</option>
                        <option value="1">New South Wales</option>
                        <option value="8">Northern Territory</option>
                        <option value="2">Queensland</option>
                        <option value="3">South Australia</option>
                        <option value="4">Tasmania</option>
                        <option value="5">Victoria</option>
                        <option value="6">Western Australia</option>
                    </select>
                </div>
                <div class="form-group row" v-if="forms.postalcode.mandatory">
                    <label for="postalcode">Postal Code</label>
                    <input
                        id="postalcode"
                        v-model="recipients.postalcode"
                        type="text"
                        class="form-control"
                        placeholder="Enter Postal Code"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.region.mandatory">
                    <label for="region">Region</label>
                    <input
                        id="region"
                        v-model="recipients.region"
                        type="text"
                        class="form-control"
                        placeholder="Enter Region"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.email.mandatory">
                    <label for="email">Email</label>
                    <input
                        id="email"
                        v-model="recipients.email"
                        type="text"
                        class="form-control"
                        placeholder="Enter Email"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.account_type.mandatory">
                    <label for="account_type">Account Type</label>
                    <input
                        id="account_type"
                        v-model="recipients.account_type"
                        type="text"
                        class="form-control"
                        placeholder="Enter Account Type"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.id_type.mandatory">
                    <label for="id_type">ID Type</label>
                    <input
                        id="id_type"
                        v-model="recipients.id_type"
                        type="text"
                        class="form-control"
                        placeholder="Enter ID Type"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.id_number.mandatory">
                    <label for="id_number">ID Number</label>
                    <input
                        id="id_number"
                        v-model="recipients.id_number"
                        type="text"
                        class="form-control"
                        placeholder="Enter ID Number"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.birth_place.mandatory">
                    <label for="birth_place">Birth Place</label>
                    <input
                        id="birth_place"
                        v-model="recipients.birth_place"
                        type="text"
                        class="form-control"
                        placeholder="Enter Birth Place"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.birth_date.mandatory">
                    <label for="birth_date">Birth Date</label>
                    <input
                        id="birth_date"
                        v-model="recipients.birth_date"
                        type="date"
                        class="form-control"
                        placeholder="Enter Birth Date"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.address.mandatory">
                    <label for="address">Address</label>
                    <input
                        id="address"
                        v-model="recipients.address"
                        type="text"
                        class="form-control"
                        placeholder="Enter Address"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.occupation.mandatory">
                    <label for="occupation">Occupation</label>
                    <input
                        id="occupation"
                        v-model="recipients.occupation"
                        type="text"
                        class="form-control"
                        placeholder="Enter Occupation"
                        required
                    />
                </div>
                <div class="form-group row" v-if="forms.nationality.mandatory">
                    <label for="nationality">Nationality</label>
                    <input
                        id="nationality"
                        v-model="recipients.nationality"
                        type="text"
                        class="form-control"
                        placeholder="Enter Nationality"
                        required
                    />
                </div>
                <div class="text-right">
                    <b-button variant="secondary" @click="hideModal">Cancel</b-button>
                    <button type="submit" class="btn btn-success ml-1" style="width:5rem" :disabled="isDisable">
                      <span v-if="submitted"><b-spinner class="p-0" style="width:20px;height:20px" variant="black" role="status"></b-spinner></span>
                      <span v-else>Submit</span>
                    </button>
                </div>
                </template>
            </b-tab>
            
        </b-tabs>
      </form>
    </b-modal>
    <!-- end modal -->
    <!-- Modal -->
    <b-modal
      id="modal-1"
      v-model="showmodal1"
      title="Recipient Details"
      title-class="text-dark font-18"
      hide-footer
      scrollable
      centered
      @show="onChange2"
      @hidden="onChange2"
    >
      <form>
        <template>
          <div class="table-responsive table-striped">
            <table class="table mb-0">
              <tbody>
                <tr>
                  <th scope="row">Full Name</th>
                  <td>{{data.recipient_name}}</td>
                </tr>
                <tr>
                  <th scope="row">Destination Country</th>
                  <td>{{data.des_country}}</td>
                </tr>
                <tr v-if="data.acc_number">
                  <th scope="row">Account Number</th>
                  <td>{{data.acc_number}}</td>
                </tr>
                <tr v-if="data.phone_n">
                  <th scope="row">Phone Number</th>
                  <td>{{data.phone_n}}</td>
                </tr>
                <tr v-if="data.iban">
                  <th scope="row">IBAN</th>
                  <td>{{data.iban}}</td>
                </tr>
                <tr>
                  <th scope="row">Bank Name</th>
                  <td>{{data.usd_bank_name ? data.usd_bank_name + ' (' + data.nama_bank+ ')' : data.nama_bank}}</td>
                </tr>
                <tr v-if="data.swift">
                  <th scope="row">Swift Code</th>
                  <td>{{data.swift}}</td>
                </tr>
                <tr v-if="data.ifsc">
                  <th scope="row">IFSC</th>
                  <td>{{data.ifsc}}</td>
                </tr>
                <tr v-if="data.bsb_code">
                  <th scope="row">BSB Number</th>
                  <td>{{data.bsb_code}}</td>
                </tr>
                <tr v-if="data.bik_code">
                  <th scope="row">BIK Code</th>
                  <td>{{data.bik_code}}</td>
                </tr>
                <tr v-if="data.sort_code">
                  <th scope="row">Sort Code</th>
                  <td>{{data.sort_code}}</td>
                </tr>
                <tr v-if="data.transit_code">
                  <th scope="row">Transit Code</th>
                  <td>{{data.transit_code}}</td>
                </tr>
                <tr v-if="data.branch_name">
                  <th scope="row">Branch Name</th>
                  <td>{{data.branch_name}}</td>
                </tr>
                <tr v-if="data.branch_code">
                  <th scope="row">Branch Code</th>
                  <td>{{data.branch_code}}</td>
                </tr>
                <tr v-if="data.bank_add">
                  <th scope="row">Bank Address</th>
                  <td>{{data.bank_add}}</td>
                </tr>
                <tr v-if="data.address">
                  <th scope="row">Address</th>
                  <td>{{data.address}}</td>
                </tr>                
                <tr v-if="data.city">
                  <th scope="row">City</th>
                  <td>{{data.city}}</td>
                </tr>
                <tr v-if="data.province_name">
                  <th scope="row">Province</th>
                  <td>{{data.province_name}}</td>
                </tr>
                <tr v-if="data.postalcode">
                  <th scope="row">Postal Code</th>
                  <td>{{data.postalcode}}</td>
                </tr>
                <tr v-if="data.email">
                  <th scope="row">Email</th>
                  <td>{{data.email}}</td>
                </tr>
                <tr v-if="data.occupation">
                  <th scope="row">Occupation</th>
                  <td>{{data.occupation}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>
      </form>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>